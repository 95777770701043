@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Code:wght@300;400;500;700&family=Fira+Sans:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Code:wght@300;400;500;700&family=Fira+Sans:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Code:wght@300;400;500;700&family=Fira+Sans:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Code:wght@300;400;500;700&family=Fira+Sans:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*

  Fonts
  font-family: 'Bebas Neue', cursive;
font-family: 'Fira Code', monospace;
font-family: 'Fira Sans', sans-serif;

*/

body {
  font-family: 'Fira Sans', monospace;
  background-image: url(/static/media/background.28f54fd1.jpg);
  background-size: cover;
  background-position: center;
}

section.projects, section.profile {
  background-color: rgba(50, 50, 50, .85);
  padding-top: 10vh;
  min-height: 100vh;
}




/* 
    Fonts
    font-family: 'Bebas Neue', cursive;
    font-family: 'Fira Code', monospace;
    font-family: 'Fira Sans', sans-serif;
*/

* {
  outline: unset;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
}

button.navbar-brand {
  font-size: 2em;
  color: rgb(255, 255, 255);
  transition: 0.75s ease;
}

button.navbar-brand:hover {
    color: rgb(235, 0, 0);
}

header {
  background-color: #1F1F1F;
  font-family: 'Fira Code', monospace;
  font-weight: bold;
}

nav div.hamburger-outer {
  position: absolute;
  top: 25px;
  right: 25px;
  height: 2.25em;
  width: 2.25em;
  border-radius: 2px;
  overflow: hidden;
}

nav div.hamburger-outer:hover {
  cursor: pointer;
}

nav div.hamburger-inner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0.25em;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
}

nav div.hamburger-inner:nth-child(2) {
  position: absolute;
  top: 0.75em;
  height: 0.25em;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 100;
}

nav div.hamburger-inner:last-child {
  position: absolute;
  top: 1.5em;
  height: 0.25em;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 100;
}

div.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  z-index: 1000;
  transition: 150ms;
  background-image: linear-gradient(to bottom, #1F1F1F 38%, #3D3D3D);
}

button.close-menu {
  background: transparent;
  outline: unset;
  border: unset;
  position: absolute;
  top: 0;
  right: 0.4em;
  font-size: 5em;
  color: white;
  transition: 2s ease;
}

button.close-menu:focus,
button.close-menu:active {
  outline: unset;
  border: unset;
}
button.close-menu:hover,
div.menu ul li:hover,
a.navbar-brand:hover {
  text-decoration: none;
  color: rgb(235,0,0);
  cursor: pointer;
}

div.menu ul {
  text-align: center;
  list-style: none;
  margin-top: 3em;
  padding: 2em 0;
}

div.menu ul li {
  padding-top: .05em;
  font-weight: normal;
  font-family: 'Fira Code', monospace;
  font-size: 2.25em;
  color: white;
  transition: 0.75s ease;
}

/*

  Fonts
  font-family: 'Bebas Neue', cursive;
font-family: 'Fira Code', monospace;
font-family: 'Fira Sans', sans-serif;

*/
section.profile{
    padding: 10vh 0;
}
.clear {
    clear: both;
}
.profileCard {
    width: 50%;
    margin: auto;
    text-align: center;
    color: white;
    background-color: rgba(25,25,25,.8);
    overflow: hidden;
    font-family: 'Fira Code', monospace;
    padding-bottom: 2em;
}

.profileCard h2 {
    background-color: rgb(175,25,25);
    padding: .5em;
}

.profileCard img {
    width: 15em;
    float: right;
    box-shadow: 0 0 20px rgb(175,25,25);
    margin: 1em 3em 1em 1em;
    border-radius: 50%;
}

.profileCard p.elevatorPitch {
    padding: 3em;
    font-size: 1.2em;
    text-align: left;
}

.profileCard h3 {
    background-color: rgb(100,100,100);
    padding: .5em;
}

article.funFacts {
    padding: 2em 3em;
    text-align: left;
    font-size: 1.5em;
}

p.quote {
    font-family: 'Fira Code', monospace;
    font-size: 1.5em;
    padding-top: 1em;
}


@media only screen and (max-width: 1023px) {
    .profileCard { 
        width: 85%;
    }
    
}

@media only screen and (max-width: 767px){
    .profileCard img {
        width: 50%;
        margin: auto;
        float: none;
    }

    .profileCard p.elevatorPitch {
        padding: 1em;
        text-align: center;
    }

    article.funFacts {
        font-size: 1.25em;
        padding: 1em;
    }
    p.quote {
        font-size: 1.25em;
        padding: .5em;
    }
}

/*

  Fonts
  font-family: 'Bebas Neue', cursive;
font-family: 'Fira Code', monospace;
font-family: 'Fira Sans', sans-serif;

*/

section.projects {
    text-align: center;
    padding: 0 !important;
}

section.projects h1 {
    font-size: 3em;
    padding: .1em;
    border-bottom: 4px solid rgb(255,50,50);
    color: rgb(255,255,255);
    background-color: rgb(255,50,50);
    font-family: 'Fira Code', monospace;
}
a {
    font-family: 'Fira Code', monospace;
    display: block;
    font-size: 5em;
    padding: .25em;
    color: rgb(255,255,255) !important;
    background-color: rgba(100,100,100, .15) !important;
    font-weight: bold;
    margin: 10px auto;
    transition: .5s ease-in-out;
}

a:hover{
    color: rgb(255, 50, 50) !important;
    background-color: rgba(255,255,255,.5) !important;
    text-decoration:none !important;
}
@media only screen and (max-width: 1023px){
    section.projects {
        padding-top: 15vh;
      }
      
    a {
        font-size: 2em;
        background-color: rgba(100,100,100,.5) !important;
    }
}
