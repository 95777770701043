/*

  Fonts
  font-family: 'Bebas Neue', cursive;
font-family: 'Fira Code', monospace;
font-family: 'Fira Sans', sans-serif;

*/
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Code:wght@300;400;500;700&family=Fira+Sans:wght@300;400;700&display=swap');

section.projects {
    text-align: center;
    padding: 0 !important;
}

section.projects h1 {
    font-size: 3em;
    padding: .1em;
    border-bottom: 4px solid rgb(255,50,50);
    color: rgb(255,255,255);
    background-color: rgb(255,50,50);
    font-family: 'Fira Code', monospace;
}
a {
    font-family: 'Fira Code', monospace;
    display: block;
    font-size: 5em;
    padding: .25em;
    color: rgb(255,255,255) !important;
    background-color: rgba(100,100,100, .15) !important;
    font-weight: bold;
    margin: 10px auto;
    transition: .5s ease-in-out;
}

a:hover{
    color: rgb(255, 50, 50) !important;
    background-color: rgba(255,255,255,.5) !important;
    text-decoration:none !important;
}
@media only screen and (max-width: 1023px){
    section.projects {
        padding-top: 15vh;
      }
      
    a {
        font-size: 2em;
        background-color: rgba(100,100,100,.5) !important;
    }
}