/*

  Fonts
  font-family: 'Bebas Neue', cursive;
font-family: 'Fira Code', monospace;
font-family: 'Fira Sans', sans-serif;

*/
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Code:wght@300;400;500;700&family=Fira+Sans:wght@300;400;700&display=swap');

body {
  font-family: 'Fira Sans', monospace;
  background-image: url(/src/images/background.jpg);
  background-size: cover;
  background-position: center;
}

section.projects, section.profile {
  background-color: rgba(50, 50, 50, .85);
  padding-top: 10vh;
  min-height: 100vh;
}



