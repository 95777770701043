/*

  Fonts
  font-family: 'Bebas Neue', cursive;
font-family: 'Fira Code', monospace;
font-family: 'Fira Sans', sans-serif;

*/
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fira+Code:wght@300;400;500;700&family=Fira+Sans:wght@300;400;700&display=swap');
section.profile{
    padding: 10vh 0;
}
.clear {
    clear: both;
}
.profileCard {
    width: 50%;
    margin: auto;
    text-align: center;
    color: white;
    background-color: rgba(25,25,25,.8);
    overflow: hidden;
    font-family: 'Fira Code', monospace;
    padding-bottom: 2em;
}

.profileCard h2 {
    background-color: rgb(175,25,25);
    padding: .5em;
}

.profileCard img {
    width: 15em;
    float: right;
    box-shadow: 0 0 20px rgb(175,25,25);
    margin: 1em 3em 1em 1em;
    border-radius: 50%;
}

.profileCard p.elevatorPitch {
    padding: 3em;
    font-size: 1.2em;
    text-align: left;
}

.profileCard h3 {
    background-color: rgb(100,100,100);
    padding: .5em;
}

article.funFacts {
    padding: 2em 3em;
    text-align: left;
    font-size: 1.5em;
}

p.quote {
    font-family: 'Fira Code', monospace;
    font-size: 1.5em;
    padding-top: 1em;
}


@media only screen and (max-width: 1023px) {
    .profileCard { 
        width: 85%;
    }
    
}

@media only screen and (max-width: 767px){
    .profileCard img {
        width: 50%;
        margin: auto;
        float: none;
    }

    .profileCard p.elevatorPitch {
        padding: 1em;
        text-align: center;
    }

    article.funFacts {
        font-size: 1.25em;
        padding: 1em;
    }
    p.quote {
        font-size: 1.25em;
        padding: .5em;
    }
}
